import { Injectable } from '@angular/core';
import { EncryptionService } from './encryption.service';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    constructor(
        private encryptionService: EncryptionService
    ) { }


    set(key: string, value: string, isSessionStorage: boolean = false): void {
        const storage = localStorage;
        const encryptedValue = this.encryptionService.encrypt(value);
        storage.setItem(key, encryptedValue);
    }

    get(key: string, isSessionStorage: boolean = false): string | null {
        const storage = localStorage;
        const encryptedValue = storage.getItem(key);
        return encryptedValue ? this.encryptionService.decrypt(encryptedValue) : null;
    }

    remove(key: string): void {
        localStorage.removeItem(key);
        sessionStorage.removeItem(key);
      }

}