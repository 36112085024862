import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {DBService, UploadService} from '../../service';
import {ContainerEvents} from './types';
import {
    AnalysisDetails, AnalysisKey,
    Analyte,
    FileObject,
    FileObjectStatus, RDSAnalysisData,
    Uploads
} from '../../model/analysis-details.model';
import {AuthService} from '../../service';
import {User} from '../../model/types';
import {SharedService} from '../../layouts/shared-service';
import {Subject} from 'rxjs/Subject';
import 'rxjs/add/operator/debounceTime';
import { DialogComponent } from '../dashboard/dialog/dialog.component';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { HelpDialogComponent } from './help-dialog/help-dialog.component';
import { delay } from 'rxjs/operators';
import { AuthNewService } from '../../service/auth-new.service';
import { StorageService } from '../../service/storage.service';


@Component({
    selector: 'app-doc-upload',
    templateUrl: './doc-upload.component.html',
    styleUrls: ['./doc-upload.component.scss']
})
export class DocUploadComponent implements OnInit {
    disabledValue;
    colorLabel = 'DarkBlue';
    uploadDisabled = false;
    pageTitle = 'New Analysis';
    files: FileObject[] = [];
    signedInUser: User;
    @Input()  analysisDetails: AnalysisDetails;
    analyte: Analyte;
    modelChanges: Subject<string> = new Subject<string>();
    finish = false;
    step = 0;
    disableAdd = false;
    verificationStatus = true;
    verificationMessage: string;
   

    // upload_types_RSG = ['Template', 'SampleFiles', 'IntraAssayPrecision', 'Summary'];
    upload_types_RSG = ['SampleFiles'];
    upload_types_AP = ['Report'];
    upload_types_SAM = ['Sample Analysis Report', 'Method Validation Report'];
    subtype: AnalysisDetails;
    private sub:User;
   
    constructor(private _sharedService: SharedService,
                private authService: AuthService,
                private dbService: DBService,
                private newAuthService: AuthNewService,
                private storageService: StorageService,
                private router: Router,
                private uploadService: UploadService,
                public dialog: MatDialog,
                private ref: ChangeDetectorRef) {
        // this._sharedService.emitChange(this.pageTitle);
        this.newAuthService.getCurrentUser();
        this.newAuthService.getCurrentLoginUser.subscribe((user: any) => {
            this.signedInUser = user;
            this.sub=user?.signInUserSession?.accessToken?.payload?.sub;
        
            this.uploadService.setSignedInUser(this.signedInUser);
            if (!this.signedInUser) {
                this.router.navigate(['/extra-layout/signin']);
                return;
            }
        });
    }

    ngOnInit() {
        this.uploadService.fileUploadEvent$.subscribe(
            fileObject => this.handleFileUploadEvent(fileObject)
        );
        this.uploadService.disabledValue.subscribe(res => {
            this.disabledValue = res;
        })

        this.setRegion();
        // console.log('Passes Object',  this.analysisDetails);
        // House Keeping - Initialize necessary objects
        this.analysisDetails.analytes = [];
        this.analyte =  new Analyte(this.analysisDetails.analyteNames[0])

        setTimeout(() => {
            this.analysisTypeFilter(this.analyte);
        }, 2000)



        this.subtype = this.analysisDetails;
    }

    analysisTypeFilter(analysis) {
       
        if (this.analysisDetails.analysisType === 'RSG') {
            this.analyte.uploadObjs = [];
            let i = 0;
            for (const upload_type of this.upload_types_RSG) {
                const obj: Uploads = {
                    uploadType: upload_type,
                    index: i,
                    path: this.getUploadPath(upload_type),
                    files: []
                };
                this.analyte.uploadObjs.push(obj);
                i++;
            }
        } else if (this.analysisDetails.analysisType === 'CHR') {
            this.analyte.uploadObjs = [];
            const uploadType = 'Upload Study DataSet/Batches';
            const obj: Uploads = {
                uploadType: uploadType,
                index: 0,
                path: this.getUploadPath('Report'),
                files: []
            };
            this.analyte.uploadObjs.push(obj);

            // tslint:disable-next-line: max-line-length
        } else if (this.analysisDetails.analysisType === 'SMR' || this.analysisDetails.analysisType === 'LMR' ||
            this.analysisDetails.analysisType === 'ADA' || this.analysisDetails.analysisType === 'AP') {
            this.analyte.uploadObjs = [];
            let uploadType = 'Report'
            if (this.analysisDetails.analysisSubtype === 'SA0') {
                uploadType = 'Sample Analysis Report'
            } else if (this.analysisDetails.analysisSubtype === 'MV0') {
                uploadType =  'Method Validation Report'
            }
            if (this.analysisDetails.analysisSubtype === 'SAM') {
                let i = 0;
                for (const upload_type of this.upload_types_SAM) {
                    const obj: Uploads = {
                        uploadType: upload_type,
                        index: i,
                        path: this.getUploadPath(upload_type),
                        files: []
                    };
                    this.analyte.uploadObjs.push(obj);
                    i++;
                }
            } else {
                const obj: Uploads = {
                    uploadType: uploadType,
                    index: 0,
                    path: this.getUploadPath('Report'),
                    files: []
                   
                };
              
                this.analyte.uploadObjs.push(obj);
                // console.log(this.analyte.uploadObjs);

            }
        }
    }

    private handleFileUploadEvent(fileObject: FileObject) {
        if (fileObject.status === FileObjectStatus.Deleted) {
            for (const upload of this.analyte.uploadObjs) {
                for (let i = 0; i < upload.files.length; i++) {
                    if (upload.files[i] === fileObject) {
                        upload.files.splice(i, 1);
                    }
                }
            }

        }
    }

    fileChangeEvent(fileInput: any, uploadType: string) {
        // console.log(fileInput)
        this.verificationStatus = true;
        this.disableAdd = false;
        const validFileExtensions = [".xlsx", ".xls", ".docx", ".csv"];
        let files = fileInput.target.files
        let filename = files[0].name

        // tslint:disable-next-line: forin
        for (const index in this.analyte.uploadObjs) {
            if (this.analyte.uploadObjs[index].uploadType === uploadType) {
                let fileValid = false;
                if (fileInput.target.files && fileInput.target.files.length) {

                    for (let j = 0; j < validFileExtensions.length; j++) {
                        let Extension = validFileExtensions[j];
                        if (filename.substr(filename.length - Extension.length, Extension.length).toLowerCase() == Extension.toLowerCase()) {
                            fileValid = true;
                            for (let i = 0; i < fileInput.target.files.length; i++) {
                                const fileObject = new FileObject(fileInput.target.files[i], uploadType);
                                // console.log(fileObject)
                                this.analyte.uploadObjs[index].files.push(fileObject);
                            }
                            break;
                        }
                    }

                }
                if (!fileValid) {
                    this.verificationStatus = false;
                    this.verificationMessage = "Sorry, " + filename + " is invalid, allowed extensions are: " + validFileExtensions.join(", ")
                    return false;
                }

            }

        }
        fileInput.target.value = null;
    }

    getUploadObj(): RDSAnalysisData {
        const analysisKeyObj: RDSAnalysisData = {
            analysis_id: this.analysisDetails.analysisId,
            group_id: this.storageService.get('group'),
            organization_id: this.storageService.get('organization'),
            user_id: this.storageService.get('username'),
            file_name: this.analyte.uploadObjs[0].files[0].name,
            analysis_type: this.analysisDetails.analysisType,
            project_code: this.analysisDetails.projectCode_AP,
        };

        return analysisKeyObj;
    }
    uploadAll(uploadType: string) {
        this.initiateAnalysis();
        setTimeout(() => {
            // console.log(this.verificationStatus)
            if (this.verificationStatus) {
                // console.log('UPLOAD TYPE');
                this.uploadService.setAnalysisData(this.analyte);
                this.uploadService.publishUploadContainerEvent(ContainerEvents.Upload);
                this.updateAnalysisDetails(uploadType);
                this.uploadDisabled = true;
                this.disableAdd = true;
            } else {
                this.analyte.uploadObjs[0].files = [];
            }
        }, 3000)
    }
    initiateAnalysis() {
        const analysisKeyObj = this.getUploadObj()
        this.dbService.verifyAnalysis(analysisKeyObj).subscribe({
            next: data => {
                // console.log('DATA on Component page');
                // console.log(data.analysisID);
                if (data.processcode === 'Error') {
                    this.verificationStatus = false;
                    this.verificationMessage = 'Error, file not uploaded: file with identical name previously uploaded. Please rename the file and upload it again to continue.'
                    // console.log(data.processcode)
                }
            },
            error: err => {
                this.verificationStatus = false;
                this.verificationMessage = 'Processing Error, Please contact Administrator.';
                // console.log(err)
            }
        });
    }

    uploadSingle(uploadType: string) {
        this.uploadService.setAnalysisData(this.analyte);
        this.uploadService.publishUploadContainerEvent(ContainerEvents.Upload);
        this.updateAnalysisDetails(uploadType);
    }

    getUploadPath(uploadType: string) {
        // console.log(this.sub,"yy")
       
        const relativePath = [this.signedInUser?.username,"us-east-2:" + this.sub,
            this.analysisDetails?.analysisId].join('/');

        if (uploadType === 'Template') {
            return [relativePath, uploadType].join('/');
        } else {
            return  [relativePath, this.analyte.analyteName, uploadType].join('/');
        }
    }

    updateAnalysisDetails(uploadType: string) {

        let analyteFound = false;
        let typefound = false;
        if (this.analysisDetails.analytes.length > 0) {


            let uploadObj: Uploads;
            // console.log('this.analyte.uploadObjs ', this.analyte.uploadObjs);
            for (const obj of this.analyte.uploadObjs) {
                if (obj.uploadType === uploadType) {
                    uploadObj = obj;

                }
            }


            for (let i = 0; i < this.analysisDetails.analytes.length; i++) {
                if ( this.analysisDetails.analytes[i].analyteName === this.analyte.analyteName) {
                    analyteFound = true;
                    for (let j = 0; j < this.analysisDetails.analytes[i].uploadObjs.length; j++) {
                        if (this.analysisDetails.analytes[i].uploadObjs[j].uploadType === uploadType) {
                            typefound = true;
                            // console.log('update object' , uploadObj.files);
                            this.analysisDetails.analytes[i].uploadObjs[j].files = []
                            this.analysisDetails.analytes[i].uploadObjs[j].files
                                .push(...JSON.parse(JSON.stringify(uploadObj.files)));
                            // console.log('xyz', this.analysisDetails.analytes[i].uploadObjs[j].files.length)

                            break;
                        }
                    }
                    if (!typefound) {
                        // console.log('Should be unreachable code, FLAG ERROR ', uploadType);
                        for (let k = 0; k < this.analyte.uploadObjs.length; k++) {
                            if (this.analyte.uploadObjs[k].uploadType === uploadType) {
                                this.analysisDetails.analytes[i].uploadObjs.push(JSON.parse(JSON.stringify(this.analyte.uploadObjs[k])));
                            }
                        }
                    }
                }
            }
            if (!analyteFound) {
                this.analysisDetails.analytes.push(JSON.parse(JSON.stringify(this.analyte)));
            }
        } else {
            this.analysisDetails.analytes.push(JSON.parse(JSON.stringify(this.analyte)));
        }
    }



    clearAll() {
        // console.log('clear All function');
        this.uploadService.publishUploadContainerEvent(ContainerEvents.Delete);
    }


    setRegion() {
        const queryParams = this.router.routerState.snapshot.root.queryParams;
        if (queryParams && queryParams.region) {
            this.uploadService.setRegion(queryParams.region);
        }
    }

    onChangeAnalysisType(str) {
        this.modelChanges.next(str);
    }

    openDialog() {
        // console.log('type', data);
        // this.dialog.open(HelpDialogComponent, {data: {name: data}, disableClose: false, width: '82%'});
        this.router.navigate(['/default-layout/template', {
            a_type: this.subtype.analysisType, a_subtype: this.subtype.analysisSubtype
        }]);
    }


    setStep(index: number) {
        this.step = index;
        this.uploadService.publishUploadContainerEvent(ContainerEvents.Delete);
    }

    nextStep() {
        this.uploadService.publishUploadContainerEvent(ContainerEvents.Delete);
        this.step++;
    }

    prevStep() {
        this.step--;
        this.clearAll();
    }

    submit() {
        this.uploadService.submitForAnalysis(this.analysisDetails).subscribe(
            (result) => {
                // console.log(' result', result);
                // console.log(this.analysisDetails.analysisType);
                if (this.analysisDetails.analysisType === 'RSG') {
                    // console.log('Navigating to Analysis Details');
                    // tslint:disable-next-line: max-line-length
                    this.router.navigate(['/default-layout/analysis-details' , this.analysisDetails.analysisId, this.signedInUser.username ]);
                } else if (this.analysisDetails.analysisType === 'SMR'  || this.analysisDetails.analysisType === 'LMR'  ||
                    this.analysisDetails.analysisType === 'ADA' || this.analysisDetails.analysisType === 'AP') {
                    this.router.navigate(['/default-layout/molecule-analysis' , this.analysisDetails.analysisId,
                        this.signedInUser.username]);
                } else if (this.analysisDetails.analysisType === 'CHR' ) {
                    this.router.navigate(['/default-layout/chromatograms' , this.analysisDetails.analysisId ]);
                } else {
                    this.router.navigate(['/default-layout/large-molecule' , this.analysisDetails.analysisId ]);
                }
            },
            (error) => {
                // console.log(error);
            }
        );

    }

}
