import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { SharedService } from '../../layouts/shared-service';
import { AnalysisDetails, DropDown, SearchResult } from '../../model/analysis-details.model';
import { DropDownService } from '../../service/drop-down.service';
import { AuthService, DBService } from '../../service';
import { User } from '../../model/types';
import { Router } from '@angular/router';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER, SPACE, } from '@angular/cdk/keycodes';
import moment from 'moment';
import { AuthNewService } from '../../service/auth-new.service';
import { delay } from 'rxjs/operators';



@Component({
  selector: 'app-new-analysis',
  templateUrl: './new-analysis.component.html',
  styleUrls: ['./new-analysis.component.scss']
})
export class NewAnalysisComponent implements OnInit {

  pageTitle = 'Analyze Report/Data';
  signedInUser: User;
  analysisFormGroup: FormGroup;
  analysisFormGroup_AP: FormGroup;
  lts80_dialog: FormControl = new FormControl();
  visible = true;
  selectable = true;
  removable = true;
  values = [];

  analysisDetails: AnalysisDetails;

  post: any = '';

  pattern1 = '^([a-zA-Z0-9., :-])*([a-zA-Z0-9., :-])+?$';
  stdConc_pattern = '^[-]?[0-9]+([,.][0-9]+)?$';
  stdConc_validator: boolean;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];

  Tags: string[] = [];
  maxDate;
  multipleAnalyteError = false;

  addOnBlur = true;

  constructor(private formBuilder: FormBuilder,
    private _sharedService: SharedService,
    private authService: AuthNewService,
    private router: Router,
    private dbService: DBService,
    private dropDownService: DropDownService,
    private newAuthService: AuthNewService,
    private ref: ChangeDetectorRef) {
        this._sharedService.emitChange(this.pageTitle);
  }

  ngOnInit() {
    const currentDate = moment().toDate();
    this.maxDate = currentDate;
    this.newAuthService.getCurrentUser();
    this.newAuthService.getCurrentLoginUser.pipe(delay(1000)).subscribe((user: any) => {
      // console.log(user);
      this.signedInUser = user;
      if (!this.signedInUser) {
        this.router.navigate(['/extra-layout/signin']);
        return;
      }
    });

    this.createForm();
    // console.log(this.Groups);
  }


  createForm() {
    this.analysisFormGroup = this.formBuilder.group({
      analysisType: [null, Validators.required],
      analysisSubtype: [null],

      projectCodeSA: ['', [Validators.maxLength(50)]],
      projectCodeMV: [null],
      regressionModel: [null],
      lloq: [null],
      uloq: [null],
      re_type: [true],
      re_value: [null],
      lts80_dialog: [null, [Validators.maxLength(10)]],
      lts20_dialog: [null],
      extraction_dialog: [null, [Validators.maxLength(10)]],
      benchtop_dialog: [null, [Validators.maxLength(10)]],
      freeze_thaw_dialog: [null, [Validators.pattern('^[0-9]+([.][0-9]+)?$'), Validators.maxLength(3)]],
      multipleAnalytes: ['No'],
      first_patient_dialog: [null],
      template: [''],
      sampleMatrix: ['', [Validators.pattern('[a-zA-Z][a-zA-Z ]+'), Validators.maxLength(50)]],
      analysisSubtype_ADA: [null],
      antiCoagulant: ['', [Validators.pattern('^[A-Za-z0-9? ,_-]+$'), Validators.maxLength(50)]],
      species: ['', [Validators.pattern('[a-zA-Z][a-zA-Z ]+'), Validators.maxLength(50)]],
      samplePrep: [null],
      sampleDate: ['', [Validators.max(this.maxDate)]],

      analyteNames: this.formBuilder.array([this.formBuilder.control([], Validators.maxLength(100))]),
    });

    this.analysisFormGroup_AP = this.formBuilder.group({
      analyteNames: this.formBuilder.array([this.formBuilder.control([], Validators.pattern(this.pattern1))]),
      analysisType: ['AP'],
      analysisSubtype: ['logistic'],
      projectCode_AP: ['', [Validators.pattern(this.pattern1), Validators.maxLength(50), Validators.required]]
    })

    this.ref.detectChanges();
  }

  get formControls() { return this.analysisFormGroup.controls; }

  get formControlsAP() { return this.analysisFormGroup_AP.controls; }


  get analyteNames() {
    // tslint:disable-next-line: max-line-length
    return this.analysisFormGroup_AP.get('analyteNames') as FormArray;
  }


  addAnalyte() {
    this.analyteNames.push(this.formBuilder.control('', Validators.pattern(this.pattern1)));
  }


  // remove Analyte from group
  removeAnalyte(index) {
    // this.analyteNames = this.form.get('analyteNames') as FormArray;
    this.analyteNames.removeAt(index);
  }


  onSubmit(post) {
    // console.log('Submit for Analysis')
    // tslint:disable-next-line: max-line-length

    this.analysisDetails = Object.assign({}, this.analysisFormGroup_AP.value);
    // console.log(this.analysisDetails);

    this.analysisDetails.userId = this.signedInUser.username;
    this.analysisDetails.analysisId = (new Date).getTime().toString();
    this.post = post;
  }


  resetAnalysis() {
    // console.log('RESET Called');
  }

  // function to check the multiple values checked or not.
  multipleAnalyteValues() {
    const multipleChecked = this.analysisFormGroup.value.multipleAnalytes;
    const count = this.analysisFormGroup.value.analyteNames;
//     console.log(multipleChecked);
//     console.log(count);

    // tslint:disable-next-line: triple-equals
    if (multipleChecked === 'True' && count.length == 1) {
//       console.log('error');
      // tslint:disable-next-line: no-unused-expression
      this.analysisFormGroup.invalid;
    } else {
//       console.log('no error');
    }

  }

  getDisplayString(key) {
    return this.dropDownService.nameDescMap.get(key);
  }

  getDropDownValues(key) {
    return this.dropDownService.dropDownsMap.get(key);
  }
}
