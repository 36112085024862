import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUser,
  ICognitoUserAttributeData,
  CognitoUserAttribute,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import {
  CognitoIdentityCredentials,
  config as AWSConfig,
  AWSError,
} from 'aws-sdk';

import { User, SignupData, NewUser } from '../model/types';
import { Dictionary } from '../../types';
import { URLUtil } from '../../utils';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { CognitoCallback, CognitoUtil } from '../service/cognito.service';
import { StorageService } from '../service/storage.service'
import * as AWS from 'aws-sdk/global';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public static statusCodes = {
    success: 'success',
    signedIn: 'signedIn',
    signedOut: 'signedOut',
    incompletedSigninData: 'incompletedSigninData',
    newPasswordRequired: 'newPasswordRequired',
    verificationCodeRequired: 'verificationCodeRequired',
    passwordChanged: 'passwordChanged',
    mfaCodeRequired: 'mfaCodeRequired',
    noSuchUser: 'noSuchUser',
    unknownError: 'unknownError',
  };

  private static userPoolLoginKey = `cognito-idp.${environment.userPool.region}.amazonaws.com/${environment.userPool.UserPoolId}`;

  authIsLoading = new BehaviorSubject<boolean>(false);
  authDidFail = new BehaviorSubject<boolean>(false);
  failMessage = new BehaviorSubject<string>('');
  payloadData = new BehaviorSubject<any>('');
  fullPayloadData = new BehaviorSubject<any>('');
  registeredUser: CognitoUser;

  private userPool = new CognitoUserPool(environment.userPool);
  private previousAppParams: any;
  private signupData: SignupData = {};
  cognitoAwsCredentials: CognitoIdentityCredentials;
  currentStatus = 'unknown';

  private userLoggedIn = new Subject<boolean>();

  

  constructor(private router: Router,
              private storageService: StorageService,
              private cognitoUtil: CognitoUtil) {
    this.userLoggedIn.next(false);
  }

  private getCognitoUser(username?: string) {
    username = username || this.signupData.username;
    if (!username) {
      return undefined;
    }
    return new CognitoUser({
      Username: username,
      Pool: this.userPool,
    });
  }

 
  setUserLoggedIn(userLoggedIn: boolean) {
    this.userLoggedIn.next(userLoggedIn);

 
  }

  getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }

 

  private onLoginError = (callback: CognitoCallback, err) => {
    callback.cognitoCallback('Incorrect username or password.', null);
  };
 

  authenticate(user: SignupData, callback?: CognitoCallback) {
    const authService = this;
    console.log('UserLoginService: starting the authentication');

    const authenticationData = {
        Username: user.username,
        Password: user.password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = {
        Username: user.username,
        Pool: this.cognitoUtil.getUserPool()
    };

    console.log('UserLoginService: Params set...Authenticating the user');
    console.log(callback);
    const cognitoUser = new CognitoUser(userData);
    console.log('UserLoginService: config is ' + AWS.config);
    cognitoUser.authenticateUser(authenticationDetails, {
      mfaRequired: (challengeName, challengeParameters) => {
        callback.handleMFAStep(challengeName, challengeParameters, (confirmationCode: string) => {
                cognitoUser.sendMFACode(confirmationCode, {
                onSuccess: result => callback.cognitoCallback(`SuccessWithMFA`, null),
                onFailure: err => callback.cognitoCallback(`Fail`, null)
            });
        });
      
      },

      



      newPasswordRequired: (userAttributes, requiredAttributes) => {
        callback.cognitoCallback('User needs to reset password.', null);
        callback.newUserPassword(userAttributes, requiredAttributes, (newPassword: string) => {
          if (authService.signupData) {
            userAttributes = Object.assign(userAttributes, authService.signupData.additionalData);
          }
          delete userAttributes.email_verified;
          delete userAttributes.phone_number_verified;
          delete userAttributes.phone_number;
          cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
            onSuccess: result => callback.cognitoCallback('New Password Updated.', ''),
            onFailure: error => {
              // console.log(error);
              callback.cognitoCallback('New Password Updated.', '')
            }
            });
          })
      },
      onSuccess: result => callback.cognitoCallback(`SuccessWithoutMFA`, null),
      onFailure: err => this.onLoginError(callback, err),
    });
}

  forgotPassword(
    username: string,
    callback: (error: Error, statusCode: string) => void
  ) {
    const authService = this;
    this.signupData.username = username;
    const cognitoUser = this.getCognitoUser(username);
    cognitoUser.forgotPassword({
      onSuccess: function () {
        authService.currentStatus =
          AuthService.statusCodes.verificationCodeRequired;
        callback(null, AuthService.statusCodes.verificationCodeRequired);
      },
      onFailure: function (err) {
        authService.currentStatus = AuthService.statusCodes.unknownError;
        if (err.name === 'UserNotFoundException') {
          callback(err, AuthService.statusCodes.noSuchUser);
        } else {
          callback(err, AuthService.statusCodes.unknownError);
        }
      },
      inputVerificationCode: function () {
        authService.currentStatus =
          AuthService.statusCodes.verificationCodeRequired;
        callback(null, AuthService.statusCodes.verificationCodeRequired);
      },
    });
  }

  confirmPassword(
    verficationCode: string,
    newPassword: string,
    callback: (error: Error, statusCode: string) => void
  ) {
    if (!this.signupData.username) {
      callback(
        new Error('Username is Empty.'),
        AuthService.statusCodes.incompletedSigninData
      );
      return;
    }
    const authService = this;
    const cognitoUser = new CognitoUser({
      Username: this.signupData.username,
      Pool: this.userPool,
    });

    cognitoUser.confirmPassword(verficationCode, newPassword, {
      onSuccess: () => {
        authService.currentStatus = AuthService.statusCodes.passwordChanged;
        callback(null, AuthService.statusCodes.success);
      },
      onFailure: (err: Error) => {
        authService.currentStatus = AuthService.statusCodes.unknownError;
        callback(err, AuthService.statusCodes.unknownError);
      },
    });
  }

  signout() {
    const currentUser = this.userPool.getCurrentUser();
    if (currentUser) {
      this.userPool.getCurrentUser().signOut();
    }
    this.router.navigate(['']);
  }

  getUserAttributes(callback: (err?: Error, data?: Dictionary<any>) => void) {
    this.getCurrentCognitoUser((err1, cognitoUser) => {
      if (!cognitoUser) {
        callback(new Error('Cognito User is not found'));
        return;
      }
      cognitoUser.getUserAttributes(
        (err2?: Error, cognitoAttributes?: CognitoUserAttribute[]) => {
          if (err2) {
            callback(err2);
            return;
          }
          cognitoAttributes = cognitoAttributes || [];
          const cognitoAttributesObject = cognitoAttributes.reduce(function (
            prev,
            curr
          ) {
            prev[curr.getName()] = curr.getValue();
            return prev;
          },
            {});
          callback(undefined, cognitoAttributesObject);
        }
      );
    });
  }

  setUserAttribute(
    name: string,
    value: string,
    callback: (err?: Error) => void
  ) {
    this.getCurrentCognitoUser((err, cognitoUser) => {
      if (!cognitoUser) {
        callback(new Error('Cognito User is not found'));
        return;
      }
      const cognitoAttributes: ICognitoUserAttributeData[] = [
        { Name: name, Value: value },
      ];
      cognitoUser.updateAttributes(cognitoAttributes, callback);
    });
  }

  addAdditionalSignupData(name: string, value: string) {
    this.signupData.additionalData = this.signupData.additionalData || {};
    this.signupData.additionalData[name] = value;
  }

  private getCurrentCognitoUser(
    callback: (err1?: Error, cognitoUser?: CognitoUser) => void
  ) {
    const cognitoUser = this.userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession((err: Error, session: CognitoUserSession) => {
        if (session && session.isValid()) {
          const payload = session.getIdToken().decodePayload()?.password_expiry_days
          const fullPayload = session.getIdToken().decodePayload();
          // const fullPayload = session;
          this.payloadData.next(payload);
          this.fullPayloadData.next(fullPayload);
          if (
            !this.cognitoAwsCredentials ||
            this.cognitoAwsCredentials.needsRefresh()
          ) {
            this.updateAWSCredentials(
              session.getIdToken().getJwtToken(),
              cognitoUser.getUsername(),
              (err2) => {
                if (err2) {
                  callback(err2);
                } else {
                  callback(undefined, cognitoUser);
                }
              }
            );
          } else {
            callback(undefined, cognitoUser);
          }
        } else {
          callback(undefined, undefined);
        }
      });
    } else {
      callback(undefined, undefined);
    }
  }

  getCurrentUser(callback: (err?: Error, user?: User) => void) {
   
    this.getCurrentCognitoUser((err, cognitoUser) => {
      if (cognitoUser && cognitoUser.getUsername()) {
        const identityId = this.cognitoAwsCredentials
          ? this.cognitoAwsCredentials.identityId
          : undefined;
        callback(
          undefined,
          new User(true, cognitoUser.getUsername(), identityId)
        );
      } else {
        callback(undefined, User.default);
      }
    });

 
  }

  private updateAWSCredentials(
    sessionToken: string,
    username: string,
    callback: (err?: Error) => void
  ) {
    const logins = {};
    logins[AuthService.userPoolLoginKey] = sessionToken;
    this.cognitoAwsCredentials = new CognitoIdentityCredentials(
      {
        IdentityPoolId: environment.identityPoolId,
        Logins: logins,
        LoginId: username,
      },
      {
        region: environment.userPool.region,
      }
    );
    // call refresh method in order to authenticate user and get new temp credentials
    this.cognitoAwsCredentials.refresh((err: AWSError) => {
      if (err) {
        callback(err);
      } else {
        AWSConfig.credentials = this.cognitoAwsCredentials;
        callback(null);
      }
    });
  }

  setPreviousAppParams(params: any) {
    this.previousAppParams = params;
  }

  handleRedirect() {
    console.log('Handling Redirect');
    if (this.previousAppParams && this.previousAppParams.from) {
      let params = '';
      if (this.previousAppParams.params) {
        params = decodeURIComponent(this.previousAppParams.params).replace(
          /from=[^&]+&/g,
          ''
        );
      }

      window.location.href = `${URLUtil.getBaseUrl()}/${this.previousAppParams.from
        }#/?from=signin&${params}`;
    } else {
      this.storageService.remove('xyz');
      // console.log('Going to Dashboard');
      this.router.navigate(['default-layout/previous-analysis']);
    }
  }

  redirectToSignin(params?: any) {
    const queryParamString = URLUtil.toQueryParamString(params).replace(
      /from=[^&]+&/g,
      ''
    );
    const encodedParams = encodeURIComponent(queryParamString);
    window.location.href = `${URLUtil.getBaseUrl()}/signin#/?from=upload&params=${encodedParams}`;
  }

  getAuthenticatedUser() {
    return this.userPool.getCurrentUser();
  }

  signUp(newUser: NewUser): void {
    // this.authIsLoading.next(true);
    const attrList: CognitoUserAttribute[] = [];
    console.log('User ', newUser);
    const emailAttribute = {
      Name: 'email',
      Value: newUser.email.trim(),
    };
    const nameAttribute = {
      Name: 'name',
      Value: newUser.name.trim(),
    };
    const phoneAttribute = {
      Name: 'phone_number',
      Value: newUser.phone.toString().trim(),
    };
    const organization = {
      Name: 'custom:organization',
      Value: newUser.organization.trim(),
    };
    // const promotionCode = {
    //     Name: 'custom:promotionCode',
    //     Value: newUser.promotionCode.trim()
    // };

    attrList.push(new CognitoUserAttribute(emailAttribute));
    attrList.push(new CognitoUserAttribute(nameAttribute));
    attrList.push(new CognitoUserAttribute(organization));
    // attrList.push(new CognitoUserAttribute(promotionCode));
    attrList.push(new CognitoUserAttribute(phoneAttribute));

    this.userPool.signUp(
      newUser.username,
      newUser.password,
      attrList,
      null,
      (err, result) => {
        if (err) {
          console.log(err.message);
          this.authDidFail.next(true);
          this.failMessage.next(err.message);
          this.authIsLoading.next(false);
          return;
        }
        console.log('result ', result);
        this.authDidFail.next(false);
        this.authIsLoading.next(true);
        this.registeredUser = result.user;
      }
    );
    return;
  }

  confirmUser(username: string, code: string) {
    this.authIsLoading.next(true);
    const userData = {
      Username: username,
      Pool: this.userPool,
    };
    const cognitUser = new CognitoUser(userData);
    cognitUser.confirmRegistration(code, true, (err) => {
      if (err) {
        console.log('Error while confirming user', err);
        this.authDidFail.next(true);
        this.authIsLoading.next(false);
        return;
      }
      this.authDidFail.next(false);
      this.authIsLoading.next(false);
      this.router.navigate(['/']);
    });
  }

  
}
