import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DBService } from '../../../service/DB.service';
import { AuthNewService } from '../../../service/auth-new.service';
import { StorageService } from '../../../service/storage.service';

@Component({
  template: ``
})
export class UserSignOutComponent implements OnInit {

  constructor(private authService: AuthNewService,
              private dbService: DBService,
              private storageService: StorageService,
              private router: Router) { }

  ngOnInit(): void {
    this.recordLogoutData();
    this.authService.signout();
    this.router.navigate(['extra-layout/home']);
  }

  recordLogoutData() {
    // console.log('Attempting to record login');
    this.storageService.remove('xyz');
    this.storageService.remove('login-data');
    this.storageService.remove('analysis-data');
    this.storageService.remove('role');
    localStorage.removeItem('submit')
    sessionStorage.clear();
    localStorage.clear();
    this.dbService.recordAuthEvent('logout').subscribe((data: string) => {
      // console.log('RECORDED LOGOUT DATA', data);
    });
  }
}
