import {
  Component,
  OnInit
} from '@angular/core';
import { Router } from '@angular/router';
import {AuthService} from '../../../service/auth.service';
import {DBService} from '../../../service';
import { StorageService } from '../../../service/storage.service';

@Component({
  template: ``
})
export class SignoutComponent implements OnInit {

  constructor(private authService: AuthService,
              private dbService: DBService,
              private storageService: StorageService,
              private router: Router) { }

  ngOnInit() {
    this.recordLogoutData();
    this.authService.signout();
  }

  recordLogoutData() {
    // console.log('Attempting to record login');
    this.storageService.remove('xyz');
    this.storageService.remove('login-data');
    this.storageService.remove('analysis-data');
    this.dbService.recordAuthEvent('logout').subscribe((data: string) => {
      // console.log('RECORDED LOGOUT DATA', data);
    });
  }
}
