import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isJSDocThisTag } from 'typescript';
import { AuthNewService } from '../../../service/auth-new.service';

@Component({
  selector: 'app-user-forgot-password',
  templateUrl: './user-forgot-password.component.html',
  styleUrls: ['./user-forgot-password.component.scss']
})
export class UserForgotPasswordComponent implements OnInit {

  forgetPasswordForm: FormGroup;
  forgetPasswordConfirmForm: FormGroup;
  codeRecieved = false;
  submitted = false;
  errorMessageForgot;
  errorMessageConfirm;
  username
  passwordPattern = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?!.*\s)(?=.*?[#?!@$%^&*-]).{8,}$'

  constructor(private fb: FormBuilder, private authservice: AuthNewService, private router: Router) { }

  ngOnInit(): void {
    this.forgetPasswordForm = this.fb.group({
      username: ['', Validators.required]
    })

    this.forgetPasswordConfirmForm = this.fb.group({
      code: ['', Validators.required],
      password: ['', [Validators.required, Validators.pattern(this.passwordPattern)]]
    })
  }
  get formControls() { return this.forgetPasswordForm.controls; }
  get formControls1() { return this.forgetPasswordConfirmForm.controls; }

  UserForgetPassword() {
    const name = this.forgetPasswordForm.value.username;
    this.username = name;
    this.authservice.forgetPassword(name);
    setTimeout(() => {
      this.authservice.forgotPasswordCode.subscribe((res) => {
        if (res === true) {
          this.codeRecieved = true;
        } else {
          // this.errorMessageForgot = 'No user found.'
          this.errorMessageForgot = 'If the user exists they will receive an email';
        }
      })
    }, 2000)
  }

  UserForgetPasswordConfirm() {
    const code = this.forgetPasswordConfirmForm.value.code;
    const password = this.forgetPasswordConfirmForm.value.password;
    this.authservice.forgetPasswordConfirm(this.username, code, password);
    setTimeout(() => {
      this.authservice.forgotPasswordMessage.subscribe((message) => {
        // console.log(message);
        if (message === 'password changed') {
          this.router.navigate(['default-layout/previous-analysis'])
        } else if(message === 'Error occured'){
          this.errorMessageForgot = message;
        }
      })
    }, 2000)
    
  }
}
