import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TemplateService } from '../../service/template.service';
import { AppLoaderService } from '../../service/app-loader/app-loader.service';
import { Templates, TemplateDetail } from '../../model/analysis-details.model';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss']
})
export class TemplateComponent implements OnInit {
  title = 'Please wait';
  public form: FormGroup;
  unsubscribe: any;
  public tableNames: any[];
  selected = '';
  selectedType;
  selectedDesc;
  fields = [];
  flag = false;
  selected1 = ''
  templateData: Templates[];
  enabled: boolean;


  constructor(private templateService: TemplateService,
              private loader: AppLoaderService,
              private changeDetector: ChangeDetectorRef,
              private route: ActivatedRoute,
              private toastr: ToastrService) {


  }

  openDialog() {
    this.loader.open(this.title);
    setTimeout(() => {
      this.loader.close();
    }, 10000)
  }

  ngOnInit() {

    const analysis_type = this.route.snapshot.paramMap.get('a_type');
    const analysis_subtype = this.route.snapshot.paramMap.get('a_subtype');
//     console.log(analysis_subtype, analysis_type);
    if (analysis_type && analysis_subtype !== null) {
      const name = null;
      this.templateService.getTableData(name, analysis_type, analysis_subtype).subscribe((table_names: any[]) => {
        this.tableNames = table_names;
      });
    } else {
      this.templateService.getTableData().subscribe((table_names: any[]) => {
        this.tableNames = table_names;
      });
    }
  }

  closeDialog() {
    this.loader.close();
  }

  tableBackCall() {
    const columns = [];
    this.templateService.getTableData(this.selected).subscribe((t_data: any[]) => {
      columns.push(t_data[0].columns);
      this.fields = columns;
      this.selectedType = t_data[0].type
      this.selectedDesc = t_data[0].description
      this.flag = true
      this.form = new FormGroup({
        fields: new FormControl(JSON.stringify(columns[0]))
      })
      this.enabled = true;
    });
  }

  tableValue(val) {
    this.selected = val;
    this.enabled = false;
    this.changeDetector.detectChanges();
    this.tableBackCall()
  }


   submittedData(event) {
    this.templateService.sendTableData(this.selectedType, this.selectedDesc, event)
    .subscribe((res) => {
      this.toastr.success('Your data have been saved successfully.');
    }, (error) => {
      this.toastr.error('Error while saving your data.')
    });
  }

}
